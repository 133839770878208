// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-formula-parser-in-javascript-example-index-js": () => import("./../../../src/pages/posts/formula-parser-in-javascript/example/index.js" /* webpackChunkName: "component---src-pages-posts-formula-parser-in-javascript-example-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

